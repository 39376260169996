<template>
  <div class="wrap">
		<img class="gauge-bg" src="../assets/img/gauge_bg.png" alt="">
		<div class="title">
			<svg xmlns="http://www.w3.org/2000/svg" width="91.85" height="43" viewBox="0 0 91.85 43">
				<path id="路径_1541" data-name="路径 1541" d="M11.25-8.45,9.9-17.8l1.95-2.35,25.9-.55,2.05,2.15-.7,9.5-2,1.85L27.8-7v.95L41.75-6.2l.05,3.8-14,.15v.75l17.75-.25.05,4.7-41.2.4-.05-4.6,18.75-.2V-2.2L9.15-2.05,9.1-5.9l14-.1v-.9l-9.8.15ZM6.8-33.55l2.85-.05-.3-2.6,1.95-2.25,27.8-.95,2.15,2.2-.85,9.7-2,1.8-26,.2-2-1.75-.3-2.6-3.2.1ZM5.75-24.5l38.85-.75.05,3.8L5.8-20.7Zm7.95-9.2,18.9-.55.05,3.75-18.45.55.05.4,22.3-.15L37-35.25l-23.35.8Zm.65,18.35,8.75-.2v-.8l-8.85.2ZM15-10.8l8.1-.15v-.8l-8.2.2Zm20.25-.4.05-.85-7.5.15v.85ZM27.8-16.45v.8l7.75-.15.05-.85ZM59.05-36.6l13.7-.55.05-2.35,4.75.1-.05,2.05,15-.65.2,4.7-15.25.65-.05,3.3,12.3-.5.15,4.75-12.5.5v2.2l17.15-.9.25,4.75L76.6-17.6l-.05.1,6.65,5.6,6.75-4.15,2.4,4L86.95-8.7,96.2-.9,93.1,2.65,72.9-14.45,69.05-11.5l.3,9.35,6.2-2.65,1.9,4.3-9.4,4L64.8,1.45,64.4-8,56.7-2.1,53.8-5.85,68.7-17.2l-12.25.65L56.2-21.3l16.4-.85.05-2.25-9.4.35-.2-4.75,9.65-.35v-3.3l-13.45.55Z" transform="translate(-4.35 39.5)" fill="#ffda00"/>
			</svg>
		</div>
		<ul class="list">
			<li v-for="(item, index) in lists" :key="index" @click="toQA(item.id)">
				<div>
					<div class="list-title">{{item.title}}</div>
					<div class="list-intro">{{item.intro}}</div>
				</div>
				<img class="gauge-btn" src="../assets/img/gauge_btn.png" alt="">
			</li>
		</ul>
		<div class="tip">请点击对应自量表查看详情</div>
	</div>
</template>

<script>
// import Vue from "vue"
import { Toast  } from 'vant'
import { getQuestionnaireList } from '../common/api/index'
export default {
  data() {
    return {
			lists: []
		};
  },
  created() {
		this.findList()

		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, module_id: 5, page: this.$route.path})
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {
		/**
		 * 获取问卷详情
		 */
		findList () {
			let that = this
			getQuestionnaireList({page_nums: 1, page_size: 10}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					that.lists = res.data.questionnaire_list
				} else {
					Toast(res.msg)
				}
			})
		},

		/**
		 * 跳转问卷
		 */
		toQA (id) {
			this.$router.push('/info?id=' + id)
		}
	},
  components: {},
};
</script>

<style scoped lang="scss">
.gauge-bg{
	width: 100%;
	position: fixed;
	top: 200px;
	left: 0;
}
.title{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 128px;

	svg{
		width: 91px;
	}
}

.list{
	width: 75%;
	margin: auto;
	font-size: 20px;
	padding-bottom: 150px;

	li{
		width: 100%;
		margin: auto;
		margin-top: 11px;
		position: relative;
		padding-bottom: 20px;
		display: flex;;
		align-items: center;
		justify-content: space-between;

		&:first-child{
			margin-top: 50px;
		}

		&::before{
			content: '';
			width: 100%;
			height: 5px;
			background: url('../assets/img/gauge_line.png') top center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.list-title{
			color: #49FCFC;
			font-size: 32px;
			margin-bottom: 14px;
		}

		.gauge-btn{
			width: 90px;
		}
	}
}
.tip{
	width: 100%;
	font-size: 32px;
	color: #FFDA00;
	text-align: center;
	position: fixed;
	bottom: 90px;
	left: 0;
}
</style>
